<template>
  <div>
      <CalendarHeader
        @on-month-change="onMonthChange"
        @on-year-change="onYearChange"
      />
      <Calendar ref="calendar" class="my-8" @on-click="onClickCell">
        <template v-slot="{day}">
            <div class="calendar-cell bottom-6">
              <span v-if="branchClassDays.includes(day)" class="inline-block bollet bollet-success"></span>
              <span v-if="onlineClassDays.includes(day)" class="inline-block bollet bollet-danger"></span>
            </div>
          </template>
      </Calendar>
      <ScheduleBottomNavigationModal @on-show-dialog="onShowDialog" />
      <Card class="mt-8">
          <div class="md-layout md-gutter p-8">
            <div class="md-layout-item md-size-33">
              <p>28 Apr 2020, 12:30PM</p>
              <p class="text-danger text-uppercase">Class schedule</p>
              <h2>Practice reading section</h2>
            </div>
            <div class="md-lalyout-item">
              <div class="h-divider"></div>
            </div>
            <div class="md-layout-item">
              <p>28 Apr 2020, 12:30PM</p>
               <p class="text-success text-uppercase">Class schedule</p>
              <h2>Practice reading section</h2>
            </div>
            <div class="md-lalyout-item">
              <div class="h-divider"></div>
            </div>
            <div class="md-layout-item">
              <p>28 Apr 2020, 12:30PM</p>
               <p class="text-uppercase" style="color: #0093ad">Live class</p>
              <h2>Live class on reading</h2>
            </div>

          </div>
      </Card>
      <Dialog>
        <component 
        :is="component"
        :title="title"
        :content="content"
        :bg-color="bgColor"
      ></component>
    </Dialog>
  </div>
</template>

<script>
import { Card, Dialog } from '@/components'
import Calendar from "@/components/atom/Calendar";
import CalendarHeader from "@/components/molecule/CalendarHeader";
import CalendarDetails from "@/components/molecule/portal/CalendarDetails";
import TabularContentDialog from "@/components/molecule/schedule/TabularContentDialog"
import MakeAnAppointment from "@/components/molecule/schedule/MakeAnAppointment";
import ScheduleBottomNavigationModal from '@/components/molecule/schedule/ScheduleBottomNavigationModal';
import { mapMutations } from 'vuex';
export default {
  components: {
    Card,
    Dialog,
    Calendar,
    CalendarHeader,
    CalendarDetails,
    MakeAnAppointment,
    TabularContentDialog,
    ScheduleBottomNavigationModal,
  },
  data() {
    return {
      component: 'TabularContentDialog',
      content: [],
      title: null,
      bgColor: null,
      branchClassDays: [7, 8, 11, 16, 24],
      onlineClassDays: [9, 11, 14, 20, 24, 29],
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onMonthChange(value) {
      this.$refs.calendar.currentMonth = value;
    },
    onYearChange(value) {
      this.$refs.calendar.currentYear = value;
    },
    onShowDialog({component, title, content, bgColor}) {
      this.component = component;
      this.title = title;
      this.content = content;
      this.bgColor = bgColor;
      this.dialog(true);
    },
    onClickCell(day) {
      let events = [...this.branchClassDays, ...this.onlineClassDays];
      if(!events.includes(day)) {
        return
      }
      this.component = 'CalendarDetails';
      this.content = {};
      this.dialog(true);
    }
  }
};
</script>

<style>
.h-divider {
  width: 1px;
  background-color: #ddd;
  height: 100%;
}
</style>
